import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import Layout from "../components/layout";
import Card from "../components/Card";
import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";

const CategoryPage = ({ data, pageContext }) => {
  const posts = data.allWpPost.edges.map(({ node }) => node);
  return (
    <Layout>
      <SEO
        keywords={[pageContext.category.name]}
        title={`Tudo sobre ${pageContext.category.name}`}
        image={get(posts, "[0].featuredImage.node.sourceUrl", null)}
        pathname={`categoria/${pageContext.category.slug}`}
      />

      <MiniHero
        type="categoria"
        name={pageContext.category.name}
        description={pageContext.category.description}
      />

      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {posts.map(node => (
            <Card key={node.slug} {...node} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
    ) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          author {
            node {
              name
            }
          }
          tags {
            nodes {
              name
              slug
              link
            }
          }
          categories {
            nodes {
              name
              slug
              link
            }
          }
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
